import React, { Component } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      etanol: '',
      gasolina: '',
      autonomiaEtanol: 8.0,
      autonomiaGasolina: 9.1,
      alertIsOpen: false,
    };
  }

  onDismiss = () => {
    this.setState({
      alertIsOpen: false,
    });
  }

  changeEtanol = (e) => {
    const { value } = e.target;

    this.setState({
      etanol: value,
    });
  }

  changeAutonomiaEtanol = (e) => {
    const { value } = e.target;

    this.setState({
      autonomiaEtanol: value,
    });
  }

  changeGasolina = (e) => {
    const { value } = e.target;

    this.setState({
      gasolina: value,
    });
  }

  changeAutonomiaGasolina = (e) => {
    const { value } = e.target;

    this.setState({
      autonomiaGasolina: value,
    });
  }

  calcular = () => {
    const {
      etanol, gasolina, autonomiaEtanol, autonomiaGasolina,
    } = this.state;

    const eficiencia = autonomiaEtanol / autonomiaGasolina;

    let mensagem;
    if (etanol <= (gasolina * eficiencia)) {
      mensagem = 'O etanol é mais vantajoso!';
    } else {
      mensagem = 'A gasolina é mais vantajosa!';
    }

    this.setState({
      alertIsOpen: true,
      mensagem,
    });
  }

  render() {
    const {
      etanol,
      gasolina,
      autonomiaEtanol,
      autonomiaGasolina,
      alertIsOpen,
      mensagem,
    } = this.state;

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <Card className="p-4">
                <CardBody>
                  <h1>Cálculo de Combustível</h1>
                  <FormGroup>
                    <Input
                      placeholder="Etanol"
                      type="number"
                      onChange={this.changeEtanol}
                      value={etanol}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      placeholder="Gasolina"
                      type="number"
                      onChange={this.changeGasolina}
                      value={gasolina}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      placeholder="Dempenho do Etanol"
                      type="number"
                      onChange={this.changeAutonomiaEtanol}
                      value={autonomiaEtanol}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      placeholder="Dempenho da  Gasolina"
                      type="number"
                      onChange={this.changeAutonomiaGasolina}
                      value={autonomiaGasolina}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button onClick={this.calcular}>Calcular</Button>
                  </FormGroup>
                  <Alert isOpen={alertIsOpen} toggle={this.onDismiss} color="primary">
                    {mensagem}
                  </Alert>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default App;
